import { jsx as _jsx } from "react/jsx-runtime";
import { ErrorBlock } from "@/components";
import InitPage from "@/components/InitPage";
import { rightRoutes, routes } from "@/routes";
import { initMp } from "@/utils/mp";
import { Suspense } from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
const router = createBrowserRouter([
    {
        ...rightRoutes,
        errorElement: _jsx(ErrorBlock, { status: "empty" }),
        shouldRevalidate: () => true,
        children: rightRoutes.children.map(({ title, path, Component, loader }) => ({
            path,
            element: (_jsx(InitPage, { title: title, children: _jsx(Component, {}) })),
            loader,
        })),
    },
    {
        children: routes.map(({ url, component: RenderComponent, title }) => ({
            path: url,
            element: (_jsx(InitPage, { title: title, children: _jsx(RenderComponent, {}) })),
        })),
    },
], { basename: PREURL });
const App = () => {
    // 初始化公众号信息
    initMp();
    return (_jsx(Suspense, { fallback: null, children: _jsx(RouterProvider, { router: router }) }));
};
export default App;
